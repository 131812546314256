import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'element-plus/theme-chalk/el-message.css';
import store from "@/store";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VideoPlayer from 'vue-video-player/src';
import  'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import live2d from 'live2d-vue3'
import 'live2d-vue3/dist/live2d-vue3.css'
import '@/assets/styles/base.less'
import '@/assets/styles/iconfont.less'
import '@/plugins/vant';
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
import { Tab, Tabs, Swipe, SwipeItem, Icon,Card,Button,Tag,ConfigProvider } from 'vant'
const app = createApp(App)
app.use(Tab)
app.use(Tabs)
app.use(Swipe)
app.use(SwipeItem)
app.use(Icon)
app.use(Card)
app.use(Button)
app.use(Tag)
app.use(vue3videoPlay);
app.use(ConfigProvider)
app.use(router)
app.use(store)
app.use(VideoPlayer)
app.use(live2d)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')