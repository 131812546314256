import axios from "axios";
import {UserData} from '../type/Users'
import {reactive} from "vue";
import {ElMessage } from 'element-plus'
import router from "@/router";

//创建axios实例
const service=axios.create({
    baseURL:"http://43.138.171.212:9090",
    timeout:5000,
    headers:{
        "Content-Type":"application/json;charset=utf-8"
    }
})
service.interceptors.request.use(config => {
    const data = reactive(new UserData())
    data.user = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (data.user){
        if (config.headers) {
            config.headers['token'] = data.user.token;//设置请求头
        }
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
service.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.code === 'CODE_401'){
            ElMessage({
                type: 'error',
                message: res.msg
            })
            router.push('/login')
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service