import { createRouter, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import {MenusInt} from '../type/menu'
import store from "@/store";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/front/home'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/:path(.*)',
    name: '404',
    component: () => import('../views/ErrorView.vue')
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: () => import('../views/detail/VideoCeShi.vue')
  },
  {
    path: '/front',
    name: 'front',
    component: () => import('../views/front/FrontView.vue'),
    children:[
      {
        path: 'home',
        name: 'FrontHome',
        component: () => import('../views/front/HomeView.vue')
      },
      {
        path: 'video',
        name: 'video',
        component: () => import('../views/front/VideoView.vue')
      },
      {
        path: 'games',
        name: 'games',
        component: () => import('../views/front/FrontMenuView.vue')
      },
      {
        path: 'film',
        name: 'film',
        component: () => import('../views/front/FilmView.vue')
      },
      {
        path: 'anime',
        name: 'anime',
        component: () => import('../views/front/AnimeView.vue')
      },
      {
        path: 'teleplay',
        name: 'teleplay',
        component: () => import('../views/front/TeleplayView.vue')
      },
      {
        path: 'variety',
        name: 'variety',
        component: () => import('../views/front/VarietyView.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('../views/front/SearchView.vue')
      }
    ]
  },
  {
    path:'/detail',
    name:'detail',
    component: () => import('../views/detail/detailView.vue'),
    children:[
      {
        path: 'content',
        name: 'content',
        component: () => import('../views/detail/detailcontentView.vue')
      },
      {
        path: 'video',
        name: 'video',
        component: () => import('../views/detail/VideoView.vue')
      }
    ]
  },
  {
    path:'/mobile',
    name:'mobile',
    component: () => import('../views/mobile/Home/HeaderMo.vue'),
  },
  { path: '/video/:id', 
    component: () => import('../views/mobile/Video/VideoMo.vue') 
  },
  { path: '/search', 
    component: () => import('../views/mobile/Search/SearchMo.vue') 
  },
  { path: '/searchResult', 
    component: () => import('../views/mobile/Search/components/search-result.vue') 
  },
  { path: '/animeView', 
    component: () => import('../views/mobile/Home/components/home-anime.vue') 
  },
  { path: '/filmView', 
    component: () => import('../views/mobile/Home/components/home-film.vue') 
  },
  { path: '/varietyView', 
    component: () => import('../views/mobile/Home/components/home-variety.vue') 
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



export const setRoutes = ()=>{
  const storeMenus = sessionStorage.getItem("menus");
  if (storeMenus){
    const currentRoutesNames = router.getRoutes().map(v => v.name)
    if(!currentRoutesNames.includes('home')){
      const manageRoute:any = {path: '/',name: 'home',component: () => import( '../views/ManageView.vue'), children:[
          {
            path: '/person',
            name: 'person',
            component: () => import( '../views/PersonView.vue')
          }
        ]}
      const menus = JSON.parse(storeMenus)
      menus.forEach((item:MenusInt) =>{
        if (item.path){
          const itemMenu = {path: item.path.replace("/",""),name: item.name,component: () => import( '../views/'+item.pagePath+'.vue')}
          manageRoute.children.push(itemMenu)
        }else if(item.children.length){
          item.children.forEach(item =>{
            if(item.path){
              const itemMenu = {path: item.path.replace("/",""),name: item.name,component: () => import( '../views/'+item.pagePath+'.vue')}
              manageRoute.children.push(itemMenu)
            }
          })
        }
      })
      router.addRoute(manageRoute)
    }
  }
}

setRoutes()


router.beforeEach((to,from,next)=>{
  localStorage.setItem("currentPathName",to.name as any)
  store.commit("setPath")
  if (!to.matched.length){
    const storeMenus = sessionStorage.getItem("menus")
    if (storeMenus){
      next("/404")
    }else {
      next("/login")
    }
  }
  next()
})

// router.afterEach((to,from,next)=>{
//   window.scrollTo(0,0)
// })
export default router
