export interface UserList{
    id:number
    username:string
    password:string
    nickname:string
    email:string
    phone:string
    address:string
    role:string
}

export interface selectDataInt{
    username:string,
    email:string,
    phone:string,
    address:string,
    page:number,//页码
    count:number, //总条数
    pagesize:number,//默认一页显示几条
    dialogFormVisible:boolean
}

export interface userInt{
    username:string
    password:string
    nickname:string
    avatarUrl:string
    token:string
    role:string
    menus:[{
        id:number
        name:string
        path:string
        icon:string
        pagePath:string
        children:[{
            id:number
            name:string
            path:string
            icon:string
            pagePath:string
        }]
    }]
}

export interface formList{
    id:number
    nickname:string
    username:string
    email:string
    phone:string
    address:string
    avatarUrl:string
}

export interface ids{
    id:number[]
}

export class UserData{
    selectData:selectDataInt={
        username:"",
        email:"",
        phone:"",
        address:"",
        page:1,
        count:0,
        pagesize:5,
        dialogFormVisible:false
    }
    formData:formList={
            id:0,
            nickname:"未命名",
            username:"",
            email:"",
            phone:"",
            address:"",
            avatarUrl:""
    }
    list:UserList[]=[]//展示的内容数据
    formDatas:formList[]=[]
    idBatch:ids={
        id:[]
    }
    user:userInt={
        username:"",
        password:"",
        nickname:"",
        avatarUrl:"",
        token:"",
        role:"",
        menus:[{
            id:0,
            name:"",
            path:"",
            icon:"user",
            pagePath:"",
            children:[{
                id:0,
                name:"",
                path:"",
                icon:"user",
                pagePath:""
            }]
        }]
    }
}