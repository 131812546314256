import service from ".";
import {selectDataInt,formList} from "../type/Users"
import {selectFileDataInt} from "../type/file"
import {LoginFormInt} from  "../type/login"
import {selectRoleDataInt,RoleList,RoleMenuInt} from "../type/role"
import {MenuList, selectMenuDataInt} from "@/type/menu";

// 登录接口
export function login(data:LoginFormInt){
    return service({
        url:"/user/login",
        method:"post",
        data
    })
}
//注册接口
export function register(data:LoginFormInt){
    return service({
        url:"/user/register",
        method:"post",
        data
    })
}

//初始验证码
export function inityanzhengma(){
    return service({
        url:"/user/inityzm",
        method:"get"
    })
}

// 商品列表接口
export function getGoodsList(){
    return service({
        url:"/getGoodsList",
        method:"get"
    })
}

//用户分页列表接口
export function pageShow(data:selectDataInt){
    return service({
        url:"/user/page?pageNum="+data.page+"&pageSize="+data.pagesize+"&username="+data.username+"&email="+data.email,
        method:"get"
    })
}

//用户增加接口
export function UserAdd(data:formList){
    return service({
        url:"/user",
        method:"post",
        data
    })
}

//用户删除接口
export function UserRemove(id:number){
    return service({
        url:"/user/"+id,
        method:"delete",
    })
}

//用户批量删除接口
export function UsersRemove(data:number[]){
    return service({
        url:"/user/del/batch",
        method:"post",
        data
    })
}

//查询单个用户接口
export function SeleteOne(data:string){
    return service({
        url:"/user/username/"+data,
        method:"get"
    })
}

//文件分页列表接口
export function pageShowFiles(data:selectFileDataInt){
    return service({
        url:"/file/page?pageNum="+data.page+"&pageSize="+data.pagesize+"&name="+data.name+"&type="+data.type,
        method:"get"
    })
}

//文件前台显示列表
export function selectFileShow(){
    return service({
        url:"/echarts/file/front/all",
        method:"get"
    })
}

//上传文件
export function uploadFile(){
    return service({
        url:"/file/upload",
        method:"post"
    })
}
//逻辑删除文件
export function isDeleteFile(id:number){
    return service({
        url:"/file/"+id,
        method:"delete"
    })
}

//逻辑批量删除文件
export function isDeleteFiles(data:number[]){
    return service({
        url:"/file/del/batch",
        method:"post",
        data
    })
}

//更新状态
export function changeStatus(data:selectFileDataInt){
    return service({
        url:"/file/update",
        method:"post",
        data
    })
}

//获取echarts数据
export function getEcharts(){
    return service({
        url:"/echarts/example",
        method:"get",
    })
}

//获取echarts数据
export function getMonEcharts(){
    return service({
        url:"/echarts/members",
        method:"get",
    })
}

//获取分页权限数据
export function pageShowRole(data:selectRoleDataInt){
    return service({
        url:"/role/page?pageNum="+data.page+"&pageSize="+data.pagesize+"&name="+data.name+"&description="+data.description,
        method:"get"
    })
}

//添加权限
export function RoleAdd(data:RoleList){
    return service({
        url:"/role",
        method:"post",
        data
    })
}

//获取权限
export function getRoles(){
    return service({
        url:"/role",
        method:"get"
    })
}

//权限删除
export function RoleRemove(id:number){
    return service({
        url:"/role/"+id,
        method:"delete",
    })
}

//用户批量删除接口
export function RolesRemove(data:number[]){
    return service({
        url:"/role/del/batch",
        method:"post",
        data
    })
}

export function pageShowMenu(data:selectMenuDataInt){
    return service({
        url:"/menu?name="+data.name,
        method:"get"
    })
}

//添加菜单
export function MenuAdd(data:MenuList){
    return service({
        url:"/menu",
        method:"post",
        data
    })
}

//菜单删除
export function MenuRemove(id:number){
    return service({
        url:"/menu/"+id,
        method:"delete",
    })
}

//菜单批量删除接口
export function MenusRemove(data:number[]){
    return service({
        url:"/menu/del/batch",
        method:"post",
        data
    })
}

//获取图标
export function getIcons(){
    return service({
        url:"/menu/icons",
        method:"get"
    })
}

//获取图标
export function getRoleMenu(data:RoleMenuInt){
    return service({
        url:"/role/roleMenu/"+data.roleId,
        method:"post",
        data:data.ids
    })
}

//获取图标
export function getRoleMenus(roleId:number){
    return service({
        url:"/role/roleMenu/"+roleId,
        method:"get"
    })
}