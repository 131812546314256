//状态集中管理
import {createStore} from "vuex";
import {SeleteOne} from "@/request/api";
import {UserData,formList,ids} from '../type/Users'
import {reactive} from "vue";
import router from "@/router";
import axios from "axios";

const store =createStore({
    state(){
        return{
            data: reactive(new UserData()),
            user :JSON.parse(sessionStorage.getItem('user') || '{}'),
            currentPathName:''
        }
    },
    getters:{
        // doneTodosCount:function (state):{
        //
        // }
        // reverMsgLength:function (state.getters){
        //
        // }
    },
    mutations:{ //用$store.commit来派发
        setPath(state:any){
            state.currentPathName = localStorage.getItem("currentPathName")
        },
        logout(){
            sessionStorage.removeItem("user")
            sessionStorage.removeItem("menus")

            //重置路由
            router.removeRoute("home")
        },
        increment(state:any){
            SeleteOne(state.user.username).then(res=>{
                if(res.code=='CODE_200'){
                    state.data.formData = res.data
                    res.data.token = state.user.token
                    sessionStorage.setItem('user',JSON.stringify(res.data)) //存储信息到session
                }
            })
        }
    },
    actions:{
        //间接调用mutations
        getHot:function (context){ //用context来获取上面的mutations //用$store.dispatch派发

        }
    }
})
//如何再App组件中通过provide提供
export default store